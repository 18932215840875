<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-text>
          <v-row class="ml-2">
            <v-col
              cols="12"
              class="d-flex flex-column justify-space-between align-center"
            >
              <v-img :src="personagem" width="380px"></v-img>
              <h3>
                Digite o CNPJ da sua empresa abaixo para iniciar o preenchimento
                do formulário.
              </h3>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="objeto.cnpj"
                label="CNPJ"
                :append-outer-icon="objeto.cnpj ? 'mdi-send' : 'md-close'"
                clear-icon="mdi-close-circle"
                clearable
                outlined
                v-validate="'required|cnpj'"
                name="cnpj"
                data-vv-scope="objeto"
                :hint="
                  errors.first('objeto.cnpj') ? errors.first('objeto.cnpj') : ''
                "
                :error="errors.collect('objeto.cnpj').length ? true : false"
                v-mask="['##.###.###/####-##']"
                persistent-hint
                @click:append-outer="buscarCnpj()"
                @click:clear="clearMessage"
                :loading="loadingCnpj"
                :disabled="disabledCnpj"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import imagem from "@/assets/images/svg/search-employeer.svg";
export default {
  name: "modal-apresentacao",
  inject: ["$validator"],
  data: () => ({
    objeto: {
      cnpj: "",
    },
    show: false,
    personagem: imagem,
    loadingCnpj: false,
    disabledCnpj: false,
  }),
  methods: {
    buscarCnpj() {
      this.loadingCnpj = true;
      this.disabledCnpj = true;
      this.$store
        .dispatch(
          "empresas/buscarEmpresaPorCnpj",
          this.$options.filters.numbersOnly(this.objeto.cnpj)
        )
        .then((resp) => {
          if (resp.data) {
            this.empresa = resp.data;
            this.dialog = false;
          } else if (resp.data == false) {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Cnpj inválido, tente novamente.",
              cor: "red",
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 5000,
            text: err.data.message,
          });
        })
        .finally(() => {
          this.loadingCnpj = false;
          this.disabledCnpj = false;
        });
    },
    clearMessage() {
      this.objeto.cnpj = "";
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["empresas/getDialogModalCnpj"];
      },
      set(value) {
        this.$store.dispatch("empresas/setDialogModalCnpj", value);
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaUsuarios"];
      },
      set(value) {
        this.$store.dispatch("empresas/setEmpresaUsuarios", value);
      },
    },
  },
};
</script>

<style></style>
