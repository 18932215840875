import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c(VRow,{staticClass:"ml-2"},[_c(VCol,{staticClass:"d-flex flex-column justify-space-between align-center",attrs:{"cols":"12"}},[_c(VImg,{attrs:{"src":_vm.personagem,"width":"380px"}}),_c('h3',[_vm._v(" Digite o CNPJ da sua empresa abaixo para iniciar o preenchimento do formulário. ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|cnpj'),expression:"'required|cnpj'"},{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##']),expression:"['##.###.###/####-##']"}],attrs:{"label":"CNPJ","append-outer-icon":_vm.objeto.cnpj ? 'mdi-send' : 'md-close',"clear-icon":"mdi-close-circle","clearable":"","outlined":"","name":"cnpj","data-vv-scope":"objeto","hint":_vm.errors.first('objeto.cnpj') ? _vm.errors.first('objeto.cnpj') : '',"error":_vm.errors.collect('objeto.cnpj').length ? true : false,"persistent-hint":"","loading":_vm.loadingCnpj,"disabled":_vm.disabledCnpj},on:{"click:append-outer":function($event){return _vm.buscarCnpj()},"click:clear":_vm.clearMessage},model:{value:(_vm.objeto.cnpj),callback:function ($$v) {_vm.$set(_vm.objeto, "cnpj", $$v)},expression:"objeto.cnpj"}})],1)],1)],1),_c(VDivider)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }